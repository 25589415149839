<template>
  <v-card color="mt-3-n" flat tile class="mb-6">
    <v-toolbar dense class="py-1" elevation="0">
      <v-btn icon @click="backToOrganizationsList">
        <v-icon class="info--text font-weight-bold">mdi-keyboard-backspace</v-icon>
      </v-btn>

      <v-toolbar-title class=" info--text font-weight-bold text--darken-1" style="{ max-width : 400px; }">
        {{ organization.legal_name }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn :class="{ 'info--text' : hover }" icon v-bind="attrs" v-on="on" @click="loadVisits">
              <v-icon color="info">mdi-calendar-account</v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span class="text-caption">Schedule Visit</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn :class="{ 'info--text' : hover }" icon v-bind="attrs" v-on="on" @click="loadUsers">
              <v-icon color="info">mdi-account-group</v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span class="text-caption">Users</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn :class="{ 'red--text' : hover }" icon v-bind="attrs" v-on="on" @click="loadLocation">
              <v-icon color="red">mdi-map-marker</v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span class="text-caption">Location</span>
      </v-tooltip>
    </v-toolbar>
    <v-divider class="mt-4"></v-divider>
    
    <ScheduleVisit v-if="scheduleVisit" v-bind:organization="organization"></ScheduleVisit>
    <Users v-if="showUsers" v-bind:organization="organization"></Users>
    <Location v-if="showLocation" v-bind:organization="organization"></Location>
  </v-card>
</template>

<script>
import ScheduleVisit from './ScheduleVisit.vue';
import Location from './Location.vue';
import Users from './Users.vue';
export default {
  components: { Users, Location, ScheduleVisit },
  name : "Dashboard",
  props : [
      'organization'
  ],
  data () {
    return {
      scheduleVisit : true,
      showUsers : false,
      showLocation : false,
    }
  },
  methods : {
    backToOrganizationsList() {
        this.$emit('goToOrganizationsList', 'Come Back');
    },
    loadVisits() {
      this.scheduleVisit = true;
      this.showUsers = false;
      this.showLocation = false;
    },
    loadLocation() {
      this.showLocation = true;
      this.showUsers = false;
      this.scheduleVisit = false;
    },
    loadUsers() {
      this.showLocation = false;
      this.showUsers = true;
      this.scheduleVisit = false;
    }
  }
}
</script>