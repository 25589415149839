// User model 
export default class Visitor {
    constructor(id, guest_name, guest_phone, event_date, from_time, end_time, category, companyName, location, host, host_id, title, id_number, reason){
        this.id = id
        this.guest_name = guest_name
        this.guest_phone = guest_phone
        this.event_date = event_date
        this.from_time = from_time
        this.end_time = end_time
        this.category = category
        this.companyName = companyName
        this.location = location
        this.host = host
        this.id_number = id_number
        this.reason = reason
        this.title = title
        this.host_id = host_id
    }
}