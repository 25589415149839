<template>
  <div>
    <v-card elevation="0">
      <v-toolbar flat>
        <v-toolbar-title class="info--text font-weight-bold text--darken-1 justify-center">
          <span>{{ showUpcomingVisits ? "Today's" : "Past" }} Visits</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn :class="{ 'green--text' : hover }" icon v-bind="attrs" v-on="on" @click="showUpcomingVisit">
                <v-icon color="green">mdi-sort-calendar-descending</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span class="text-caption">Upcoming Visits</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn :class="{ 'red--text' : hover }" icon v-bind="attrs" v-on="on" @click="showPastVisit">
                <v-icon color="red">mdi-sort-calendar-ascending</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span class="text-caption">Past Visits</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn :class="{ 'info--text' : hover }" icon v-bind="attrs" v-on="on" @click="scheduleModal">
                <v-icon color="info">mdi-calendar-plus</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span class="text-caption">Add Visit</span>
        </v-tooltip>

      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table :headers="visitHeaders" :items="visitsWithIndex">
        <template v-slot:[`item.time`]="{ item }">
          {{ item.start_at }} - {{ item.end_at }}
        </template>
      </v-data-table>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="showScheduleModal" scrollable max-width="600px">
        <v-card>
          <v-card-title class="grey--text text-h5">
            Visitor
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mb-0 pb-0">
            <v-form class="pt-7 pb-0">
              <!-- <v-container> -->
              <v-text-field dense v-model="visitor.guest_name" label="Full Name"
                            :error-messages="apiErrors.guest_name" outlined
              ></v-text-field>

              <!--  Phone Number -->
              <div class="pb-2">
                <template>
                  <vue-phone-number-input v-model="phoneNumber" @update="updatePhoneNumber"
                      :default-country-code="national_number" size="md"
                  ></vue-phone-number-input>
                  <span class="text-caption pl-4 red--text">{{ apiErrors.phone ? apiErrors.phone[0] : '' }}</span>
                  <span class="text-caption red--text" v-if="phoneValidationError">{{ phoneValidationError }}</span>
                </template>
              </div>

              <v-row>

                <!-- Company Name -->
                <v-col>
                  <v-text-field dense v-model="organization.legal_name" label="Company Name"
                      readonly :error-messages="apiErrors.companyName" outlined
                  ></v-text-field>
                </v-col>

                <!--  Location -->
                <v-col>
                  <v-text-field dense v-model="organizationFacility.facility" label="Location"
                      readonly :error-messages="apiErrors.facility_id" outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Date -->
                <v-col>
                  <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-text-field dense v-model="dateToday" label="Date"
                          v-bind="attrs" v-on="{ ...menu }" :error-messages="apiErrors.event_date" outlined>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="dateToday" color="primary" header-color="primary"></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- From -->
                <v-col>
                  <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-text-field dense v-model="startTime" label="From" v-bind="attrs" v-on="{ ...menu }"
                                    :error-messages="apiErrors.from_time" outlined>
                      </v-text-field>
                    </template>
                    <v-time-picker v-model="startTime" scrollable format="24hr"></v-time-picker>
                  </v-menu>
                </v-col>

                <!-- To -->
                <v-col>
                  <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-text-field dense v-model="endTime" label="To" v-bind="attrs" v-on="{ ...menu }"
                          :error-messages="apiErrors.end_time" outlined>
                      </v-text-field>
                    </template>
                    <v-time-picker v-model="endTime" scrollable format="24hr"></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-select dense v-model="visitor.category" :items="visitCategories" label="Category"
                  :error-messages="apiErrors.category" outlined></v-select>
              <v-autocomplete dense v-model="visitor.host_id" :items="organizationUsers"
                  item-value="id" item-text="attributes.name" label="Host"
                  :error-messages="apiErrors.host_id" outlined
              ></v-autocomplete>

              <v-text-field dense v-model="visitor.title" label="Title" :error-messages="apiErrors.title" outlined>
              </v-text-field>

              <v-text-field dense v-model="visitor.reason" label="Reason" :error-messages="apiErrors.reason" outlined>
              </v-text-field>
              <!-- </v-container> -->
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeModal" class="text-capitalize mx-1">
              Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="green darken-1 white--text" @click="saveVisit" class="text-capitalize mx-1">
              Save
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-dialog>
    </v-row>

  </div>
</template>
<script>
import Visitor from '../../models/visitor';
import organizationService from '../../services/organization.service';
import ReportsService from '../../services/reports.service';
import EventsService from '../../services/events.service';

export default {
  name: "ScheduleVisit",

  props: [
    'organization'
  ],

  data() {
    return {
      visitor: new Visitor(),
      visits: [],
      visitHeaders: [
        {
          text: "#",
          value: "index",
        }, {
          text: "Visitor",
          align: "start",
          filterable: true,
          value: "visitor.name",
        },
        {
          text: "Host",
          value: "host.name",
          filterable: false,
        },
        {
          text: "Title",
          value: "title",
          filterable: false,
        },
        {
          text: "Reason",
          value: "reason",
          filterable: false,
        },
        {
          text: "Location",
          value: "host_facility",
          filterable: false,
        },
        {
          text: "Date",
          align: "center",
          value: "event_date",
          filterable: false,
        },
        {
          text: "Time",
          align: "center",
          value: "time",
          filterable: false,
        },
        {
          text: "Status",
          value: "visitor.status",
          filterable: false,
        },
      ],
      showUpcomingVisits: true,
      organizationUsers: [],
      showScheduleModal: false,
      apiErrors: [],
      visitCategories: ["Official", "Personal"],
      closeOnContentClick: false,
      dateToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      startTime: null,
      endTime: null,

      national_number: "KE",
      phoneNumber: null,
      phoneValidationError: null,

    }
  },

  computed: {
    organizationFacility() {
      return this.organization.facility[0] ?? { 'id': null, 'facility': null };
    },

    visitsWithIndex() {
      if ((this.visits).length == 0) {
        return [];
      }

      return this.visits.map(
          (visits, index) => ({
            ...visits,
            index: index + 1
          })
      )


    },
  },

  created() {
    this.$nextTick(() => {
      this.calculateTime();
      this.getUpcomingVisits();
      this.getPastVisits();
      // this.listenToBluezoneChannel();
    });
  },

  methods: {
    // listenToBluezoneChannel() {
    //   window.Echo.private('bluezone-channel')
    //     .listen('UpdateWatchmanList', () => {
    //       console.log('Update Watchman List');
    //     })
    //     .subscribed(function() {
    //       console.log('Subscribed to Channel');
    //     })
    //     .listenToAll(function() {
    //       // listen to all events
    //     });
    // },
    saveVisit() {
      this.validatePhoneNumber();
      this.visitor.from_time = this.startTime
      this.visitor.end_time = this.endTime
      this.visitor.facility_id = this.organizationFacility.id
      this.visitor.event_date = this.dateToday

      EventsService.store(this.visitor).then(
          (response) => {
            if (response.status === 200) {
              this.$store.dispatch("alert/success", "Visitor invited");
              this.loading = false;
              this.getUpcomingVisits();
            } else {
              this.$store.dispatch("alert/error", (this.user.name ?? "Visitor") + " not invited" + response.message);
            }

            this.closeModal();
          },
          (error) => {
            if (error.response.status === 422) {
              this.apiErrors = error.response.data.errors
              console.log(error.response.data.errors)
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }

            this.$store.dispatch("alert/error", error.response.data.message);
          }
      );
    },

    scheduleModal() {
      this.calculateTime();
      this.visitor.category = "Official";
      this.getUsers();
      this.showScheduleModal = true;
    },

    closeModal() {
      this.phoneNumber = null;
      this.visitor = new Visitor();
      this.showScheduleModal = false;
    },

    calculateTime() {
      let time = new Date(Date.now());
      let hour = time.getHours();
      let minutes = time.getMinutes();

      if (minutes > 30) {
        hour += 2;
      } else {
        hour += 1;
      }

      this.startTime = hour + ":00";
      this.endTime = (hour + 1) + ":00";
    },

    toggleVisits() {
      let visits = [];
      if (this.showUpcomingVisits) {
        visits = this.upcomingVisits ?? []
      } else {
        visits = this.pastVisits ?? []
      }
      this.visits = visits
    },

    showUpcomingVisit() {
      this.getUpcomingVisits();
      this.showUpcomingVisits = true;
      this.toggleVisits();
    },

    showPastVisit() {
      this.getPastVisits();
      this.showUpcomingVisits = false;
      this.toggleVisits();
    },

    getUpcomingVisits() {
      ReportsService.upcomingVisits(this.organization.id).then(
          success => {
            this.upcomingVisits = success.data ? success.data.data : this.upcomingVisits
            this.toggleVisits();
          },
          error => {
            console.log(error)
            this.upcomingVisits = [];
          }
      )
    },

    getPastVisits() {
      ReportsService.pastVisits(this.organization.id).then(
          success => {
            this.pastVisits = success.data.data
            this.toggleVisits();
          },
          error => {
            console.log(error)
            this.pastVisits = [];
          }
      )
    },

    getUsers() {
      organizationService.organization_users(this.organization.id).then(
          success => {
            console.log(success)
            this.organizationUsers = success.data ? success.data.data : this.organizationUsers
          },
          error => {
            console.log(error)
            this.organizationUsers = [];
          }
      )
    },

    updatePhoneNumber(data) {
      this.visitor.guest_phone = data.e164
    },

    validatePhoneNumber() {
      if (this.phoneNumber == null) {
        this.phoneErrorColor = "red"
        this.phoneValidationError = "The phone number is required";
      }
    },
  }

}
</script>