<template>
  <v-card id="org-users">
    <v-data-table :headers="headers" :items="organizationUsersWithIndex">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="!showSearch" class="info--text font-weight-bold text--darken-1 justify-center">
            <span>Organization Users</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn :class="{ 'info--text' : hover }" icon v-bind="attrs" v-on="on" @click="addUser">
                  <v-icon color="info">mdi-account-plus</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span class="text-caption">Add User</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:[`item.roles`]="{ item }">

        <div v-if="(item.relationships.roles[0]).length > 0" class="py-2">
          <v-tooltip top v-for="(role,index) in item.relationships.roles[0]" :key="index">

            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-chip :class="{
                  'grey' : hover , 'green white--text' : !role.attributes.thru_date
                }" class="ma-1" v-bind="attrs" v-on="on">
                  {{ role.relationships.role[0]['role_name'] }}
                </v-chip>
              </v-hover>
            </template>

            <v-simple-table class="transparent white--text">
              <template v-slot:default>
                <thead>
                <tr border="bottom" color="red">
                  <th class="text-left white--text">
                    Role
                  </th>
                  <th class="text-center white--text">
                    From
                  </th>
                  <th class="text-center white--text">
                    Status
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr :key="role.id">
                  <td>{{ role.relationships.role[0]['role_name'] }}</td>
                  <td>{{ role.attributes.from_date }}</td>
                  <td>{{ role.attributes.thru_date ? 'Inactive' : 'Active' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-tooltip>
        </div>

        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn :class="{ 'info--text' : hover }" icon v-bind="attrs" v-on="on" @click="assignRole(item)">
                <v-icon color="info">mdi-account-tie-hat</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span class="text-caption">Assign role/s</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="editUser(item)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.delete`]="{ item }">
        <v-btn color="error" class="mx-1" fab x-small dark elevation="0" @click="deleteItem(item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-row justify="center">
      <v-dialog v-model="showUserModal" max-width="600" scrollable>
        <v-card>
          <v-card-title class="grey--text text-h5">
            User
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mb-0 pb-0">
            <v-form class="pt-7 pb-0">
              <v-text-field dense v-model="user.name" label="Full Name" :error-messages="apiErrors.name" outlined>
              </v-text-field>
              <div class="pb-2">
                <template>
                  <vue-phone-number-input
                    v-model="phoneNumber" 
                    @update="updatePhoneNumber"
                    :default-country-code="national_number" 
                    size="md"
                  ></vue-phone-number-input>
                  <span class="text-caption pl-4 red--text">{{ apiErrors.phone ? apiErrors.phone[0] : '' }}</span>
                  <span class="text-caption red--text" v-if="phoneValidationError">{{ phoneValidationError }}</span>
                </template>
              </div>
              <v-text-field dense v-model="user.email" label="Email" :error-messages="apiErrors.email" outlined>
              </v-text-field>
              <v-text-field dense v-model="user.id_number" label="ID No" :error-messages="apiErrors.id_number" outlined>
              </v-text-field>
              <v-text-field 
                dense 
                v-model="organization.legal_name" 
                label="Organization"
                readonly 
                :error-messages="apiErrors.organization_id" 
                outlined
              ></v-text-field>
              <v-select 
                v-model="user.user_roles" 
                :items="roles"
                item-text="role_name" 
                item-value="id" 
                attach 
                chips
                label="Role(s)" 
                multiple 
                :error-messages="apiErrors.user_roles" 
                outlined
              ></v-select>
              <v-text-field 
                dense 
                v-model="user.password" 
                label="Password"
                placeholder="Leave blank if you don't want to reset the user's password"
                :error-messages="apiErrors.password" 
                outlined
                type="password"
                v-if="checkIfWatchman(user.user_roles)"
              ></v-text-field>
              <v-text-field 
                dense 
                v-model="user.password_confirmation" 
                label="Password Confirmation"
                placeholder="Leave blank if you don't want to reset the user's password"
                :error-messages="apiErrors.password" 
                outlined
                type="password"
                v-if="checkIfWatchman(user.user_roles)"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn 
              color="red darken-1" 
              text 
              @click="closeModal" 
              class="text-capitalize mx-1"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn 
              v-if="editUserModal" color="green darken-1 white--text"
              @click="saveUserUpdates" 
              class="text-capitalize mx-1"
            >
              Update
            </v-btn>
            <v-btn 
              v-else 
              color="green darken-1 white--text" 
              @click="saveUser" 
              class="text-capitalize mx-1"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="showDeleteDialog" persistent width="400">
        <v-card>
          <v-card-title class="headline">
            Proceed with caution!
            <span class="float-right ml-1">
              <v-icon class="mdi mdi-alert-circle-outline"></v-icon>
            </span>
          </v-card-title>
          <v-card-text>
            You are about to delete a user,
            are you sure you want to delete this item?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeModal">Cancel</v-btn>
            <v-btn color="error" text @click="deleteUser">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog 
      v-model="assignRoleModal" 
      persistent 
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly" 
      scrollable
    >
      <v-card>
        <v-card-title class="grey--text text-h5">
          User Roles
        </v-card-title>

        <v-divider></v-divider>

        <v-form>
          <v-container>
            <v-text-field 
              v-model="user.name" 
              label="Full Name" 
              :error-messages="apiErrors.name" 
              readonly 
              outlined
            ></v-text-field>
            <v-text-field 
              v-model="organization.legal_name" 
              label="Organization"
              readonly 
              :error-messages="apiErrors.organization_id" 
              outlined
            ></v-text-field>
            <v-select 
              v-model="user.user_roles" 
              :items="roles" 
              item-text="role_name"
              item-value="id" 
              attach 
              chips 
              label="Assign Role/s" 
              multiple
              :error-messages="apiErrors.user_roles" 
              outlined
            ></v-select>
          </v-container>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeModal">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 white--text" @click="saveAssignUserRoles">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import User from '../../models/user';
import OrganizationService from '../../services/organization.service'
import rolesService from '../../services/roles.service';
import UserService from '../../services/user.service';

export default {
  name: "Users",
  props: [
    'organization'
  ],
  data() {
    return {
      user: new User(),
      organizationUsers: [],
      headers: [
        {
          text: "#",
          align: "start",
          filterable: true,
          value: "index",
        }, 
        {
          text: "First Name",
          align: "start",
          filterable: true,
          value: "attributes.firstname",
        }, 
        {
          text: "Last Name",
          align: "start",
          filterable: true,
          value: "attributes.lastname",
        }, 
        {
          text: "Role",
          align: "center",
          filterable: false,
          value: "roles",
        }, 
        {
          text: "From Date",
          align: "start",
          filterable: true,
          value: "relationships.organization.from_date",
        }, 
        {
          text: "To Date",
          align: "start",
          filterable: true,
          value: "relationships.organization.to_date",
        }, 
        {
          text: "Edit",
          align: "center",
          filterable: false,
          value: "edit"
        }, 
        {
          text: "Delete",
          align: "center",
          filterable: false,
          value: "delete"
        }
      ],
      showUserModal: false,
      roles: [],
      apiErrors: [],
      showSearch: null,
      editUserModal: false,
      assignRoleModal: false,
      national_number: "KE",
      phoneNumber: null,
      phoneValidationError: null,
      phoneErrorColor: "blue",
      showDeleteDialog: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.getUsers();
      this.getRoles();
    });
  },
  computed: {
    organizationUsersWithIndex() {
      return this.organizationUsers.map(
          (organizationUsers, index) => ({
            ...organizationUsers,
            index: index + 1
          })
      )
    },
    watchmanRole() {
      let roleId = 0;
      let index = -1;
      index = this.roles?.findIndex((item) => (item.role_name.toLowerCase() == 'watchman' || item.role_name.toLowerCase() == 'security'));
      if (index > -1) {
        roleId = this.roles?.[index]['id'];
      }

      return roleId;
    },
  },
  methods: {
    getUsers() {
      OrganizationService.organization_users(this.organization.id).then(
        success => {
          this.organizationUsers = success.data ? success.data.data : this.organizationUsers;
        },
        error => {
          console.log(error)
          this.organizationUsers = [];
        }
      )
    },

    checkIfWatchman(roles) {
      if (roles) {
        return roles.includes(this.watchmanRole);
      } else {
        return false;
      }
    },
    saveUser() {
      if (this.phoneNumber === null) {
        this.phoneErrorColor = "red"
        this.phoneValidationError = "The phone number is required";
      }
      this.user.organization_id = this.organization.id;

      UserService.store(this.user).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch("alert/success", this.user.name + " created");
            this.loading = false;
            this.getUsers();
          } else {
            this.$store.dispatch(
                "alert/error",
                (this.user.name ?? "User") + " not created" + response.message
            );
          }

          this.closeModal();
        },
        (error) => {
          if (error.response.status === 422) {
            this.apiErrors = error.response.data.errors;
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
          }

          this.$store.dispatch(
              "alert/error",
              error.response.data.message
          );
        }
      );
    },

    saveUserUpdates() {
      this.user.organization_id = this.organization.id;

      UserService.updateOrganizationUser(this.user).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch(
                "alert/success",
                this.user.name + " updated"
            );
            this.loading = false;
            this.getUsers();

          } else {
            this.$store.dispatch(
                "alert/error",
                (this.user.name ?? "User") + " not updated" + response.message
            );
          }

          this.closeModal();
        },
        (error) => {
          if (error.response.status === 422) {
            this.apiErrors = error.response.data.errors;

            this.$store.dispatch(
                "alert/error",
                error.response.data.message
            );

            this.loading = false;
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    saveAssignUserRoles() {
      this.user.organization_id = this.organization.id;
      UserService.assignOrganizationUserRoles(this.user).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch("alert/success", this.user.name + " updated");
            this.loading = false;
            this.getUsers();
          } else {
            this.$store.dispatch("alert/error", (this.user.name ?? "User") + " not updated" + response.message);
          }

          this.closeModal();
        },
        (error) => {
          if (error.response.status === 422) {
            this.apiErrors = error.response.data.errors;
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    assignRole(user) {
      this.user.name = user.attributes.firstname + " " + user.attributes.lastname;
      this.assignRoleModal = true
      this.user.id = user.id
    },

    editUser(user) {
      this.user.id = user.id;
      this.user.name = user.attributes.firstname + " " + user.attributes.lastname;
      this.phoneNumber = user.attributes.phone;
      this.user.email = user.attributes.email;
      this.user.organization_id = this.organization.id;
      this.user.id_number = user.attributes.id_number;

      if ((user.relationships.roles[0]).length !== 0) {
        let user_roles = user.relationships.roles[0];
        let role_ids = [];

        user_roles.forEach(user_role => {
          role_ids.push(user_role.relationships.role[0].id)
        });

        this.user.user_roles = role_ids;
      }

      this.getRoles();
      this.editUserModal = true;
      this.showUserModal = true;
      this.apiErrors = [];
    },

    deleteItem(item) {
      this.showDeleteDialog = true;
      this.$nextTick(() => {
        this.user = Object.assign({}, item);
      });
    },

    // deleting the data from the database
    deleteUser() {
      UserService.deleteUser(this.user).then(
        (response) => {
          if (response.status === 200) {
            let index = this.organizationUsersWithIndex.indexOf(this.user);
            this.organizationUsersWithIndex.splice(index, 1)
            this.$store.dispatch("alert/success", "User deleted successfully", response.data.message);
            this.getUsers();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", "User not deleted", response.data.message);
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }

          if (error.response.status === 401) {
            this.handleLogout();
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    addUser() {
      this.getRoles();
      this.showUserModal = true;
      this.apiErrors = [];
    },

    getRoles() {
      rolesService.organizationRoles(this.organization.id).then(
        success => {
          this.roles = success.data.data;
        },
        error => {
          let body = error.response.data.data
          this.apiErrors = body.errors;
        }
      );
    },

    closeModal() {
      this.user = new User()
      this.phoneNumber = null;
      this.assignRoleModal = false;
      this.editUserModal = false;
      this.showUserModal = false;
      this.apiErrors = [];
      this.showDeleteDialog = false;
      this.$nextTick(() => {
        this.user = Object.assign({}, new User());
      });
    },

    updatePhoneNumber(data) {
      this.user.phone = data.e164;
    },
  }
}
</script>