import Axios from './Axios';
class RolesService {

    index() {
        return Axios({ 
            method : "GET",
            url : 'roles'
        })
    }
    organizationRoles(organization_id) {
        return Axios({ 
            method : "GET",
            url : 'organization-roles/'+organization_id
        })
    }
    store(item) {
        return Axios({ 
            method : "post",
            url : 'roles',
            data : item
        })
    }
    update(item) {
        return Axios({ 
            method : "PUT",
            url : 'roles/'+item.id,
            data : item
        })
    }
    delete(item) {
        return Axios({ 
            method : "DELETE",
            url : 'roles/'+item.id
        })
    }
}

export default new RolesService()
